/*
- AS_SyncData
-- Syncs the users data
-- word add in uses  RS_UpdateAfterSync as part of this process so this on is PO only
*/
 
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import config from '@/config';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_CheckToken from '@/services/app/checkToken';
import AS_StoreSyncData from '@/services/app/storeSyncData';
import { isBefore } from 'date-fns';
import { parseISO } from 'date-fns';
// import RS_UpdateAfterSync from '@/services/reference/updateAfterSync';
import router from '@/router';
import store from '@/store';

export default ($opts) => {
	return new Promise((resolve, reject) => {
		// console.log('AS_SyncData');

		const _$opts = $opts || {};
		const _isStealthSync = (_has(_$opts, 'isStealthSync')) ? _$opts.isStealthSync : false;

		if(!_isStealthSync){
			store.commit('loaders/ADD_ID', 'App');
		}

		let params = {
			lastModifiedDate: '',
		}

		if(config.platformId === config.enums.Platform.CHROME_EXT){
			chrome.storage.local.get('lastSyncDate', (result) => {
				if(!_isEmpty(result) && result.lastSyncDate){
					// force resync if last sync date is NOT BEFORE a specific date defined in config
					if(!isBefore(parseISO(result.lastSyncDate), parseISO(config.forceResyncBeforeDate))){ //
						params['lastModifiedDate'] = result.lastSyncDate;
					}
				}
	
				ApiS_MakeCall({
					method: 'GET',
					params,
					responseType: 'json',
					url: 'v4/Sync',
				}).then((responseData)=>{
					if(!config.isLive){
						console.log('v4/sync responseData');
						console.log(responseData);
					}
					
					AS_StoreSyncData(responseData).then(()=>{
						resolve();
					}).catch(()=>{
						reject();
					});//e:AS_StoreSyncData
				});//e:ApiS_MakeCall
			});//chrome.storage.local.get:lastSyncDate

		} else {
			// force resync if last sync date is NOT BEFORE a specific date defined in config
			if(!isBefore(parseISO(store.state.lastSyncDate), parseISO(config.forceResyncBeforeDate))){ //2099-01-01T00:00:00
				if(config.platformId === config.enums.Platform.ONLINE){
					// do not set a Last Sync Date if the Customer Papers array has content (why was this here?)
					// console.log('isCustomerPapersHaveLastSyncDate');
					// console.log(isCustomerPapersHaveLastSyncDate);
					// if(store.state.paperList.list.length > 0 || isCustomerPapersHaveLastSyncDate){
						params['lastModifiedDate'] = store.state.lastSyncDate
					// }

				} else if(config.platformId === config.enums.Platform.ADD_IN){
					// 2023.07.25 - commented this checked out - i think it's logic isn't working right and some users are never getting a last modified date passed
					// if(store.state.refLibCompString != '' && store.state.refLibCompStringGenesis != '') {
						// users that have not ran the new version will have a blank string for state.refLibCompString - if that string is blank do not pass a lastModifiedDate to grab everything again
						params['lastModifiedDate'] = store.state.lastSyncDate;
					// }

				}

			} else {
				console.log('Force Full Resync');

				// clear everything out so it can be fully replaced
				store.commit('LAST_SYNC_DATE', '');
				store.commit('REFERENCE_LIBRARY', []);
				// store.commit('refLibCompString', '');
				store.commit('REFERENCE_LIBRARY_GENESIS', []);
				// store.commit('refLibCompStringGenesis', '');
			}

			AS_CheckToken().then(()=>{
				ApiS_MakeCall({
					method: 'GET',
					params,
					responseType: 'json',
					url: 'v4/Sync',
				}).then((responseData)=>{
					if(!config.isLive){
						console.log('v4/sync responseData');
						console.log(responseData);
					}

					// check user account subscription date
					let useExpirationDate;	// declare here and set in the next if block
		
					// check if user is in an organization
					if(responseData.customer.organizationID === 0){
						// NOT in an org - Check subscription expiration date
						useExpirationDate = new Date(responseData.customer.subscriptionExpirationDate);
					} else {
						// IN an org - use whichever expiration date is later
						if(isBefore(parseISO(responseData.customer.subscriptionExpirationDate), parseISO(responseData.customer.organizationExpirationDate))){
							// customer is before org - use customer
							useExpirationDate = new Date(responseData.customer.organizationExpirationDate);
						} else {
							// org is before customer - use org
							useExpirationDate = new Date(responseData.customer.subscriptionExpirationDate);
						}
					}
			
					useExpirationDate.setHours(0, 0, 0, 0);
					
					// save customer level stuff - this will be needed no matter the customer's membership
					store.commit('LAST_SYNC_DATE', responseData.lastSyncDate);
					
					store.commit('customer/SET_ALTERNATIVE_EMAILS', responseData.customer.alternativeEmails);
					store.commit('customer/SET_CALENDAR_STARTING_DAY_OF_WEEK', responseData.customer.calendarStartingDayOfWeek);
					store.commit('customer/SET_CALENDAR_STARTING_HOUR', responseData.customer.calendarStartingHour);
					store.commit('customer/SET_CALENDAR_VIEW_ID', responseData.customer.calendarViewID);
					store.commit('customer/SET_CITATION_AUTO_INSERT', responseData.customer.citationAutoInsert);
					store.commit('customer/SET_EMAIL', responseData.customer.email);
					store.commit('customer/SET_EXPIRATION_DATE', useExpirationDate);
					store.commit('customer/SET_FIRST_NAME', responseData.customer.firstName);
					store.commit('customer/SET_FULL_NAME', responseData.customer.fullName);
					store.commit('customer/SET_HAS_PRIORITY_SUPPORT', responseData.customer.hasPrioritySupport);
					store.commit('customer/SET_ID', responseData.customer.customerID.toString());
					store.commit('customer/SET_INSTITUTION', responseData.customer.institution);
					store.commit('customer/SET_IS_CANCELLED', responseData.customer.isCancelled);
					store.commit('customer/SET_IS_IMPERSONATING', responseData.customer.isImpersonating);
					store.commit('customer/SET_IS_RECURRING', responseData.customer.isRecurring);
					store.commit('customer/SET_LAST_NAME', responseData.customer.lastName);
					store.commit('customer/SET_ORGANIZATION_EXPIRATION_DATE', responseData.customer.organizationExpirationDate);
					store.commit('customer/SET_ORGANIZATION_ID', responseData.customer.organizationID);
					store.commit('customer/SET_ORGANIZATION_STATUS_ID', responseData.customer.organizationStatusID);
					store.commit('customer/SET_PAPER_AUTO_SAVE', responseData.customer.paperAutoSave);

					if(
						responseData.customer.preferredFormatVersionID === '' || responseData.customer.preferredFormatVersionID === 0
					){
						// default to APA 7 if this isn't auto set for some reason from the sync call
						store.commit('customer/SET_PREFERRED_FORMAT_VERSION_ID', config.enums.Format.APA7);
					} else {
						store.commit('customer/SET_PREFERRED_FORMAT_VERSION_ID', responseData.customer.preferredFormatVersionID);
					}
					
					store.commit('customer/SET_ROLE', responseData.customer.role);
					store.commit('customer/SET_TIME_ZONE', responseData.customer.timeZone);
			
					// renewalPeriod 
					if(responseData.customer.subscriptionType === 2){
						// annual
						store.commit('customer/SET_RENEWAL_PERIOD', config.enums.SubscriptionRenewalPeriod.ANNUAL);
					} else if(responseData.customer.subscriptionType === 3){
						// semi-annual
						store.commit('customer/SET_RENEWAL_PERIOD', config.enums.SubscriptionRenewalPeriod.SEMI);
					} else if(responseData.customer.subscriptionType === 4){
						// two years
						store.commit('customer/SET_RENEWAL_PERIOD', config.enums.SubscriptionRenewalPeriod.TWO_YEARS);
					}

					// now check customer account dates
					if(useExpirationDate < config.today){
						// Account Expired - handle the router push in the catch
						if(router.currentRoute.name === 'YourPapers'){
							// loop through all papers
							responseData.papers.forEach((paper)=>{
								if(!paper.isDeleted){
									
									let paperData = {
										isComplete: paper.isComplete,
										isDeleted: paper.isDeleted,
										isOnlinePaper: true,
										lastModifiedDate: paper.lastModifiedDate || '',
										paperFormatID: paper.paperFormatID,
										paperFormatVersionID: paper.paperFormatVersionID,
										paperID: paper.paperID,
										paperType: paper.paperType,
										title: paper.title,
									}
									
									store.commit('paperList/ADD_PAPER_TO_LIST', paperData);
								}
							});//e:forEach

							return resolve();

						} else {
							router.push({
								name: 'MyProfile',
							}).catch(()=>{});
	
							return resolve();	
						}
						
		
					} else {
						// Account is Fine
						AS_StoreSyncData(responseData).then(()=>{
							// RS_UpdateAfterSync().then(()=>{
								return resolve();
							// });//e:RS_UpdateAfterSync
						}).catch((error)=>{
							console.log('AS_StoreSyncData error');
							console.log(error);
						});//e:AS_StoreSyncData
					}
				});//e:ApiS_MakeCall

			});//e:AS_CheckToken

		}//e:platform

	});//e:Promise
}